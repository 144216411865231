import { render, staticRenderFns } from "./ThePrednosti.vue?vue&type=template&id=94bc7c96&scoped=true&"
import script from "./ThePrednosti.vue?vue&type=script&lang=js&"
export * from "./ThePrednosti.vue?vue&type=script&lang=js&"
import style0 from "./ThePrednosti.vue?vue&type=style&index=0&id=94bc7c96&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94bc7c96",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
